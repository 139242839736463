import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import styles from "./IndividualNews.module.css";
import axios from "axios";

//mui components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

import NurseImage from "../../assets/images/NewsNursesImage.png";
import NurseImage1 from "../../assets/images/NurseImage1.png";
import Newsimage from "../../assets/images/newsimage1.png";
import { hover } from "@testing-library/user-event/dist/hover";

const newsPath = "/News";

const Data = [
  {
    id: 1,
    date: "May 12 2023",
    heading: "Exquisite Celebrations on International Nursing Day",
    firstPara: `A Glorious Tribute to the Guardians of Care With utmost joy and
    admiration, we commemorated the Day of Angels, International Nursing
    Day, in an unforgettable ceremony. Our esteemed Managing Director,
    the distinguished Dr. Sheeja G Manoj, graced the occasion with her
    presence, infusing the event with brilliance. Joining us as the
    esteemed Chief Guest was the remarkable Mrs. Gayatri Subhash, the
    illustrious Managing Director of BHIMA Jewellers. Special Thanks to
    Tanishq for the gifts and for sending wishes to our angels.`,
    secondPara: `We extend our heartfelt gratitude to Mrs. Gayatri Subhash and the
      dedicated staff of Bhima Jewellery, who magnanimously bestowed gifts
      upon our exceptional nurses, symbolizing their deep appreciation for
      their invaluable contributions. This generous gesture resonated with
      the profound gratitude we hold for these angelic caregivers. A
      noteworthy mention is owed to the dynamic presence of Red FM
      Malayalam RJ Chinchu and her team, who added vibrant hues of
      enthusiasm and energy to the ambience, amplifying the significance
      of this momentous occasion. On this day, we recognized the
      extraordinary devotion and unwavering spirit of our nurses, who
      embody the very essence of compassion and selflessness. Their
      commitment to healing and nurturing reflects the epitome of noble
      service. May the radiance of this event continue to shine as a
      testament to the profound impact our nurses have on the lives they
      touch, and may their selfless dedication forever inspire others to
      embrace the noble calling of healthcare. Happy International Nursing
      Day!`,
    mainImage: NurseImage,
    subImages: [
      { id: 1, image: NurseImage1 },
      { id: 2, image: NurseImage1 },
      { id: 3, image: NurseImage1 },
    ],
  },
  // Add data for other doctors if you have multiple doctors
];

const Newses = [
  {
    id: 1,
    date: "May 12, 2023",
    heading: "Could sugar consumption impact the risk of Alzheimer's disease?",
    image: Newsimage,
    details:
      "The Hepatobiliary and Transplantology Unit of GG Hospital offers a comprehensive... ",
  },
  {
    id: 2,
    date: "May 12, 2023",
    heading: "MedHeavy drinkers risk muscle loss, new study finds",
    image: Newsimage,
    details:
      "The Hepatobiliary and Transplantology Unit of GG Hospital offers a comprehensive...",
  },
  {
    id: 3,
    date: "May 12, 2023",
    heading: "MedHeavy drinkers risk muscle loss, new study finds",
    image: Newsimage,
    details:
      "The Hepatobiliary and Transplantology Unit of GG Hospital offers a comprehensive...",
  },
];

const imageArray = [
  {
    heading: "EXQUISITE CELEBRATIONS ON",
    subImages: [
      { id: 1, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/1.jpg` },
      { id: 2, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/2.jpg` },
      { id: 3, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/3.jpg` },
      { id: 4, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/4.jpg` },
      { id: 5, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/5.jpg` },
      { id: 6, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/6.jpg` },
      { id: 7, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/7.jpg` },
      { id: 8, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/8.jpg` },
      { id: 9, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/9.jpg` },
      { id: 10, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/10.jpg` },
      { id: 11, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/11.jpg` },
      { id: 12, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/12.jpg` },
      { id: 13, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/13.jpg` },
      { id: 14, image: `${newsPath}/EXQUISITE CELEBRATIONS ON/14.jpg` },
    ],
  },
  {
    heading: "VISITED PV SINDHU",
    subImages: [],
  },
  {
    heading: "FIRST HELP FOR WOMEN",
    subImages: [
      { id: 1, image: `${newsPath}/FIRST HELP FOR WOMEN/1.jpg` },
      { id: 2, image: `${newsPath}/FIRST HELP FOR WOMEN/2.jpg` },
      { id: 3, image: `${newsPath}/FIRST HELP FOR WOMEN/3.jpg` },
    ],
  },
  {
    heading: "INTERVENTIONAL RADIOLOGY STATE CONFERENCE",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/INTERVENTIONAL RADIOLOGY STATE CONFERENCE/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/INTERVENTIONAL RADIOLOGY STATE CONFERENCE/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/INTERVENTIONAL RADIOLOGY STATE CONFERENCE/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/INTERVENTIONAL RADIOLOGY STATE CONFERENCE/4.jpg`,
      },
      {
        id: 5,
        image: `${newsPath}/INTERVENTIONAL RADIOLOGY STATE CONFERENCE/5.jpg`,
      },
      {
        id: 6,
        image: `${newsPath}/INTERVENTIONAL RADIOLOGY STATE CONFERENCE/6.jpg`,
      },
      {
        id: 7,
        image: `${newsPath}/INTERVENTIONAL RADIOLOGY STATE CONFERENCE/7.jpg`,
      },
      {
        id: 8,
        image: `${newsPath}/INTERVENTIONAL RADIOLOGY STATE CONFERENCE/8.jpg`,
      },
    ],
  },
  {
    heading: "HAPPY BIRTHDAY, SHEEJA",
    subImages: [
      { id: 1, image: `${newsPath}/HAPPY BIRTHDAY, SHEEJA/1.jpg` },
      { id: 2, image: `${newsPath}/HAPPY BIRTHDAY, SHEEJA/2.jpg` },
      { id: 3, image: `${newsPath}/HAPPY BIRTHDAY, SHEEJA/3.jpg` },
      { id: 4, image: `${newsPath}/HAPPY BIRTHDAY, SHEEJA/4.jpg` },
      { id: 5, image: `${newsPath}/HAPPY BIRTHDAY, SHEEJA/5.jpg` },
      { id: 6, image: `${newsPath}/HAPPY BIRTHDAY, SHEEJA/6.jpg` },
      { id: 7, image: `${newsPath}/HAPPY BIRTHDAY, SHEEJA/7.jpg` },
      { id: 8, image: `${newsPath}/HAPPY BIRTHDAY, SHEEJA/8.jpg` },
    ],
  },
  {
    heading: "PLAYING IN THE FINALS",
    subImages: [
      { id: 1, image: `${newsPath}/PLAYING IN THE FINALS/1.jpg` },
      { id: 2, image: `${newsPath}/PLAYING IN THE FINALS/2.jpg` },
      { id: 3, image: `${newsPath}/PLAYING IN THE FINALS/3.jpg` },
    ],
  },
  {
    heading: "ASSOCIATION OF SURGICAL GASTROENTEROLOGISTS",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/ASSOCIATION OF SURGICAL GASTROENTEROLOGISTS/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/ASSOCIATION OF SURGICAL GASTROENTEROLOGISTS/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/ASSOCIATION OF SURGICAL GASTROENTEROLOGISTS/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/ASSOCIATION OF SURGICAL GASTROENTEROLOGISTS/4.jpg`,
      },
      {
        id: 5,
        image: `${newsPath}/ASSOCIATION OF SURGICAL GASTROENTEROLOGISTS/5.jpg`,
      },
      {
        id: 6,
        image: `${newsPath}/ASSOCIATION OF SURGICAL GASTROENTEROLOGISTS/6.jpg`,
      },
      {
        id: 7,
        image: `${newsPath}/ASSOCIATION OF SURGICAL GASTROENTEROLOGISTS/7.jpg`,
      },
      {
        id: 8,
        image: `${newsPath}/ASSOCIATION OF SURGICAL GASTROENTEROLOGISTS/8.jpg`,
      },
    ],
  },
  {
    heading: "GLIMPSES OF THE FLAG HOISTING",
    subImages: [
      { id: 1, image: `${newsPath}/GLIMPSES OF THE FLAG HOISTING/1.jpg` },
      { id: 2, image: `${newsPath}/GLIMPSES OF THE FLAG HOISTING/2.jpg` },
      { id: 3, image: `${newsPath}/GLIMPSES OF THE FLAG HOISTING/3.jpg` },
      { id: 4, image: `${newsPath}/GLIMPSES OF THE FLAG HOISTING/4.jpg` },
    ],
  },
  {
    heading: "GG FAMILY CLINIC JANUARY",
    subImages: [
      { id: 1, image: `${newsPath}/GG FAMILY CLINIC JANUARY/1.jpg` },
      { id: 2, image: `${newsPath}/GG FAMILY CLINIC JANUARY/2.jpg` },
      { id: 3, image: `${newsPath}/GG FAMILY CLINIC JANUARY/3.jpg` },
      { id: 4, image: `${newsPath}/GG FAMILY CLINIC JANUARY/4.jpg` },
      { id: 5, image: `${newsPath}/GG FAMILY CLINIC JANUARY/5.jpg` },
      { id: 6, image: `${newsPath}/GG FAMILY CLINIC JANUARY/6.jpg` },
    ],
  },
  {
    heading: "CELEBRATING OUR HONOURABLE VICE CHAIRMAN",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/CELEBRATING OUR HONOURABLE VICE CHAIRMAN/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/CELEBRATING OUR HONOURABLE VICE CHAIRMAN/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/CELEBRATING OUR HONOURABLE VICE CHAIRMAN/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/CELEBRATING OUR HONOURABLE VICE CHAIRMAN/4.jpg`,
      },
    ],
  },
  {
    heading: "WORLD PHARMACIST DAY",
    subImages: [
      { id: 1, image: `${newsPath}/WORLD PHARMACIST DAY/1.jpg` },
      { id: 2, image: `${newsPath}/WORLD PHARMACIST DAY/2.jpg` },
      { id: 3, image: `${newsPath}/WORLD PHARMACIST DAY/3.jpg` },
      { id: 4, image: `${newsPath}/WORLD PHARMACIST DAY/4.jpg` },
      { id: 5, image: `${newsPath}/WORLD PHARMACIST DAY/5.jpg` },
      { id: 6, image: `${newsPath}/WORLD PHARMACIST DAY/6.jpg` },
      { id: 7, image: `${newsPath}/WORLD PHARMACIST DAY/7.jpg` },
      { id: 8, image: `${newsPath}/WORLD PHARMACIST DAY/8.jpg` },
      { id: 9, image: `${newsPath}/WORLD PHARMACIST DAY/9.jpg` },
      { id: 10, image: `${newsPath}/WORLD PHARMACIST DAY/10.jpg` },
    ],
  },
  {
    heading: "NATIONAL NUTRITION MONTH",
    subImages: [
      { id: 1, image: `${newsPath}/NATIONAL NUTRITION MONTH/1.jpg` },
      { id: 2, image: `${newsPath}/NATIONAL NUTRITION MONTH/2.jpg` },
      { id: 3, image: `${newsPath}/NATIONAL NUTRITION MONTH/3.jpg` },
      { id: 4, image: `${newsPath}/NATIONAL NUTRITION MONTH/4.jpg` },
      { id: 5, image: `${newsPath}/NATIONAL NUTRITION MONTH/5.jpg` },
      { id: 6, image: `${newsPath}/NATIONAL NUTRITION MONTH/6.jpg` },
      { id: 7, image: `${newsPath}/NATIONAL NUTRITION MONTH/7.jpg` },
      { id: 8, image: `${newsPath}/NATIONAL NUTRITION MONTH/8.jpg` },
      { id: 9, image: `${newsPath}/NATIONAL NUTRITION MONTH/9.jpg` },
      { id: 10, image: `${newsPath}/NATIONAL NUTRITION MONTH/10.jpg` },
      { id: 11, image: `${newsPath}/NATIONAL NUTRITION MONTH/11.jpg` },
      { id: 12, image: `${newsPath}/NATIONAL NUTRITION MONTH/12.jpg` },
      { id: 13, image: `${newsPath}/NATIONAL NUTRITION MONTH/13.jpg` },
      { id: 14, image: `${newsPath}/NATIONAL NUTRITION MONTH/14.jpg` },
      { id: 15, image: `${newsPath}/NATIONAL NUTRITION MONTH/15.jpg` },
    ],
  },
  {
    heading: "WORLD HEART DAY MEDICAL CAMP",
    subImages: [
      { id: 1, image: `${newsPath}/WORLD HEART DAY MEDICAL CAMP/1.jpg` },
      { id: 2, image: `${newsPath}/WORLD HEART DAY MEDICAL CAMP/2.jpg` },
      { id: 3, image: `${newsPath}/WORLD HEART DAY MEDICAL CAMP/3.jpg` },
      { id: 4, image: `${newsPath}/WORLD HEART DAY MEDICAL CAMP/4.jpg` },
      { id: 5, image: `${newsPath}/WORLD HEART DAY MEDICAL CAMP/5.jpg` },
      { id: 6, image: `${newsPath}/WORLD HEART DAY MEDICAL CAMP/6.jpg` },
    ],
  },
  {
    heading: "GG FAMILY CLINIC",
    subImages: [
      { id: 1, image: `${newsPath}/GG FAMILY CLINIC/1.jpg` },
      { id: 2, image: `${newsPath}/GG FAMILY CLINIC/2.jpg` },
      { id: 3, image: `${newsPath}/GG FAMILY CLINIC/3.jpg` },
      { id: 4, image: `${newsPath}/GG FAMILY CLINIC/4.jpg` },
      { id: 5, image: `${newsPath}/GG FAMILY CLINIC/5.jpg` },
    ],
  },
  {
    heading: "CAPTURING THE MOMENTS FROM APAMT 2023",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/4.jpg`,
      },
      {
        id: 5,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/5.jpg`,
      },
      {
        id: 6,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/6.jpg`,
      },
    ],
  },
  {
    heading: "CELEBRATING STRENGTH: BREAST CANCER AWARENESS MONTH - CANERASE.2",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/CELEBRATING STRENGTH: BREAST CANCER AWARENESS MONTH - CANERASE.2/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/CELEBRATING STRENGTH: BREAST CANCER AWARENESS MONTH - CANERASE.2/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/CELEBRATING STRENGTH: BREAST CANCER AWARENESS MONTH - CANERASE.2/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/CELEBRATING STRENGTH: BREAST CANCER AWARENESS MONTH - CANERASE.2/4.jpg`,
      },
      {
        id: 5,
        image: `${newsPath}/CELEBRATING STRENGTH: BREAST CANCER AWARENESS MONTH - CANERASE.2/5.jpg`,
      },
      {
        id: 6,
        image: `${newsPath}/CELEBRATING STRENGTH: BREAST CANCER AWARENESS MONTH - CANERASE.2/6.jpg`,
      },
    ],
  },
  {
    heading: "GG FAMILY CLINIC KULATHOOR FIRST ANNIVERSARY",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/GG FAMILY CLINIC KULATHOOR FIRST ANNIVERSARY/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/GG FAMILY CLINIC KULATHOOR FIRST ANNIVERSARY/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/GG FAMILY CLINIC KULATHOOR FIRST ANNIVERSARY/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/GG FAMILY CLINIC KULATHOOR FIRST ANNIVERSARY/4.jpg`,
      },
      {
        id: 5,
        image: `${newsPath}/GG FAMILY CLINIC KULATHOOR FIRST ANNIVERSARY/5.jpg`,
      },
    ],
  },
  {
    heading: "BLOOD DONATION CAMP AT CET TRIVANDRUM",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/BLOOD DONATION CAMP AT CET TRIVANDRUM/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/BLOOD DONATION CAMP AT CET TRIVANDRUM/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/BLOOD DONATION CAMP AT CET TRIVANDRUM/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/BLOOD DONATION CAMP AT CET TRIVANDRUM/4.jpg`,
      },
      {
        id: 5,
        image: `${newsPath}/BLOOD DONATION CAMP AT CET TRIVANDRUM/5.jpg`,
      },
      {
        id: 6,
        image: `${newsPath}/BLOOD DONATION CAMP AT CET TRIVANDRUM/6.jpg`,
      },
    ],
  },
  {
    heading: "CAPTURING THE MOMENTS FROM APAMT 2023",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/4.jpg`,
      },
      {
        id: 5,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/5.jpg`,
      },
      {
        id: 6,
        image: `${newsPath}/CAPTURING THE MOMENTS FROM APAMT 2023/6.jpg`,
      },
    ],
  },
  {
    heading: "IN-HOUSE MEDICAL CAMP FOCUSED ON ONCOLOGY AT GG HOSPITAL",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/IN-HOUSE MEDICAL CAMP FOCUSED ON ONCOLOGY AT GG HOSPITAL/1.jpg`,
      },
    ],
  },
  {
    heading: "MEDICAL CAMP AT CSI CHURCH KAZHAKUTTOM",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/MEDICAL CAMP AT CSI CHURCH KAZHAKUTTOM/1.jpg`,
      },
    ],
  },
  {
    heading: "CHILDREN'S DAY CELEBRATION",
    subImages: [
      { id: 1, image: `${newsPath}/CHILDREN'S DAY CELEBRATION/1.jpg` },
      { id: 2, image: `${newsPath}/CHILDREN'S DAY CELEBRATION/2.jpg` },
      { id: 3, image: `${newsPath}/CHILDREN'S DAY CELEBRATION/3.jpg` },
      { id: 4, image: `${newsPath}/CHILDREN'S DAY CELEBRATION/4.jpg` },
      { id: 5, image: `${newsPath}/CHILDREN'S DAY CELEBRATION/5.jpg` },
      { id: 6, image: `${newsPath}/CHILDREN'S DAY CELEBRATION/6.jpg` },
      { id: 7, image: `${newsPath}/CHILDREN'S DAY CELEBRATION/7.jpg` },
      { id: 8, image: `${newsPath}/CHILDREN'S DAY CELEBRATION/8.jpg` },
      { id: 9, image: `${newsPath}/CHILDREN'S DAY CELEBRATION/9.jpg` },
    ],
  },
  {
    heading: "SPREADING JOY AND LAUGHTER AT GG FAMILY CLINIC",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/SPREADING JOY AND LAUGHTER AT GG FAMILY CLINIC/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/SPREADING JOY AND LAUGHTER AT GG FAMILY CLINIC/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/SPREADING JOY AND LAUGHTER AT GG FAMILY CLINIC/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/SPREADING JOY AND LAUGHTER AT GG FAMILY CLINIC/4.jpg`,
      },
      {
        id: 5,
        image: `${newsPath}/SPREADING JOY AND LAUGHTER AT GG FAMILY CLINIC/5.jpg`,
      },
    ],
  },
  {
    heading: "MEDICAL CAMP AT NAVAKERALA SADASS",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/MEDICAL CAMP AT NAVAKERALA SADASS/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/MEDICAL CAMP AT NAVAKERALA SADASS/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/MEDICAL CAMP AT NAVAKERALA SADASS/3.jpg`,
      },
    ],
  },
  {
    heading: "BLOOD DONATION CAMP AT MAHINDRA FINANCE",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/BLOOD DONATION CAMP AT MAHINDRA FINANCE/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/BLOOD DONATION CAMP AT MAHINDRA FINANCE/2.jpg`,
      },
    ],
  },
  {
    heading: "GG SCHOOL OF NURSING FIRST BATCH",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/GG SCHOOL OF NURSING FIRST BATCH/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/GG SCHOOL OF NURSING FIRST BATCH/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/GG SCHOOL OF NURSING FIRST BATCH/3.jpg`,
      },
    ],
  },
  {
    heading: "HARMONIZING CINEMA AND CARE",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/HARMONIZING CINEMA AND CARE/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/HARMONIZING CINEMA AND CARE/2.jpg`,
      },
    ],
  },
  {
    heading: "NAVAKERALA SADAS STRIKES AGAIN",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/NAVAKERALA SADAS STRIKES AGAIN/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/NAVAKERALA SADAS STRIKES AGAIN/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/NAVAKERALA SADAS STRIKES AGAIN/3.jpg`,
      },
    ],
  },
  {
    heading: "98TH NATIONAL CONFERENCE IMA HELD IN KOVALAM",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/98TH NATIONAL CONFERENCE IMA HELD IN KOVALAM/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/98TH NATIONAL CONFERENCE IMA HELD IN KOVALAM/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/98TH NATIONAL CONFERENCE IMA HELD IN KOVALAM/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/98TH NATIONAL CONFERENCE IMA HELD IN KOVALAM/4.jpg`,
      },
      {
        id: 5,
        image: `${newsPath}/98TH NATIONAL CONFERENCE IMA HELD IN KOVALAM/5.jpg`,
      },
      {
        id: 6,
        image: `${newsPath}/98TH NATIONAL CONFERENCE IMA HELD IN KOVALAM/6.jpg`,
      },
      {
        id: 7,
        image: `${newsPath}/98TH NATIONAL CONFERENCE IMA HELD IN KOVALAM/7.jpg`,
      },
    ],
  },
  {
    heading:
      "DR. SHEEJA G MANOJ WITH THE EMINENT LEADERSHIP IN HEALTHCARE AWARD",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/DR. SHEEJA G MANOJ WITH THE EMINENT LEADERSHIP IN HEALTHCARE AWARD/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/DR. SHEEJA G MANOJ WITH THE EMINENT LEADERSHIP IN HEALTHCARE AWARD/2.jpg`,
      },
    ],
  },
  {
    heading:
      "DR. SHEEJA G MANOJ MEETS THE OSCAR-WINNING MAESTRO, MR. RESUL POOKUTTY, AND HIS ESTEEMED WIFE SHADIA POOKUTTY",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/DR. SHEEJA G MANOJ MEETS THE OSCAR-WINNING MAESTRO, MR. RESUL POOKUTTY, AND HIS ESTEEMED WIFE SHADIA POOKUTTY/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/DR. SHEEJA G MANOJ MEETS THE OSCAR-WINNING MAESTRO, MR. RESUL POOKUTTY, AND HIS ESTEEMED WIFE SHADIA POOKUTTY/2.jpg`,
      },
    ],
  },
  {
    heading:
      "CAPTURING MOMENTS FROM OUR CELEBRATION OF RECEIVING INC APPROVAL FOR GG SCHOOL OF NURSING",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/CAPTURING MOMENTS FROM OUR CELEBRATION OF RECEIVING INC APPROVAL FOR GG SCHOOL OF NURSING/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/CAPTURING MOMENTS FROM OUR CELEBRATION OF RECEIVING INC APPROVAL FOR GG SCHOOL OF NURSING/2.jpg`,
      },
    ],
  },
  {
    heading:
      "CHAIRMAN GOKULAM GOPALAN RECEIVES DEEPIKA LIFETIME ACHIEVEMENT AWARD",
    subImages: [],
  },
  {
    heading: "INTERNATIONAL NURSES DAY CELEBRATION AT GG HOSPITAL",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/INTERNATIONAL NURSES DAY CELEBRATION AT GG HOSPITAL/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/INTERNATIONAL NURSES DAY CELEBRATION AT GG HOSPITAL/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/INTERNATIONAL NURSES DAY CELEBRATION AT GG HOSPITAL/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/INTERNATIONAL NURSES DAY CELEBRATION AT GG HOSPITAL/4.jpg`,
      },
    ],
  },
  {
    heading: "ACTRESS MS CHIPPY AND DR SHEEJA G MANOJ AT GG HOSPITAL",
    subImages: [],
  },
  {
    heading: `INSIGNIA, "CRITICAL CARE CONFERENCE AND WORKSHOP ON MECHANICAL VENTILATION"`,
    subImages: [
      {
        id: 1,
        image: `${newsPath}/INSIGNIA, "CRITICAL CARE CONFERENCE AND WORKSHOP ON MECHANICAL VENTILATION"/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/INSIGNIA, "CRITICAL CARE CONFERENCE AND WORKSHOP ON MECHANICAL VENTILATION"/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/INSIGNIA, "CRITICAL CARE CONFERENCE AND WORKSHOP ON MECHANICAL VENTILATION"/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/INSIGNIA, "CRITICAL CARE CONFERENCE AND WORKSHOP ON MECHANICAL VENTILATION"/4.jpg`,
      },
      {
        id: 5,
        image: `${newsPath}/INSIGNIA, "CRITICAL CARE CONFERENCE AND WORKSHOP ON MECHANICAL VENTILATION"/5.jpg`,
      },
      {
        id: 6,
        image: `${newsPath}/INSIGNIA, "CRITICAL CARE CONFERENCE AND WORKSHOP ON MECHANICAL VENTILATION"/6.jpg`,
      },
    ],
  },
  {
    heading: "LAMP LIGHTING CEREMONY AT GG SCHOOL OF NURSING",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/LAMP LIGHTING CEREMONY AT GG SCHOOL OF NURSING/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/LAMP LIGHTING CEREMONY AT GG SCHOOL OF NURSING/2.jpg`,
      },
    ],
  },
  {
    heading:
      "WE ARE HONORED TO HAVE CPI LEADER MR. BINOY VISWAM VISIT OUR ESTEEMED MANAGING DIRECTOR, DR. SHEEJA G MANOJ",
    subImages: [],
  },
  {
    heading: "BLOOD DONATION CAMP AT KAMALESHWARAM HIGHER SECONDARY SCHOOL",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/BLOOD DONATION CAMP AT KAMALESHWARAM HIGHER SECONDARY SCHOOL/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/BLOOD DONATION CAMP AT KAMALESHWARAM HIGHER SECONDARY SCHOOL/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/BLOOD DONATION CAMP AT KAMALESHWARAM HIGHER SECONDARY SCHOOL/3.jpg`,
      },
    ],
  },
  {
    heading:
      "DR. ANSAR P P, OUR SURGICAL ONCOLOGIST, FOR REPRESENTING GG HOSPITAL AT THE ISO-MVR CANCON 2024",
    subImages: [
      {
        id: 1,
        image: `${newsPath}/DR. ANSAR P P, OUR SURGICAL ONCOLOGIST, FOR REPRESENTING GG HOSPITAL AT THE ISO-MVR CANCON 2024/1.jpg`,
      },
      {
        id: 2,
        image: `${newsPath}/DR. ANSAR P P, OUR SURGICAL ONCOLOGIST, FOR REPRESENTING GG HOSPITAL AT THE ISO-MVR CANCON 2024/2.jpg`,
      },
      {
        id: 3,
        image: `${newsPath}/DR. ANSAR P P, OUR SURGICAL ONCOLOGIST, FOR REPRESENTING GG HOSPITAL AT THE ISO-MVR CANCON 2024/3.jpg`,
      },
      {
        id: 4,
        image: `${newsPath}/DR. ANSAR P P, OUR SURGICAL ONCOLOGIST, FOR REPRESENTING GG HOSPITAL AT THE ISO-MVR CANCON 2024/4.jpg`,
      },
    ],
  },
];

function IndividualNews() {
  const navigate = useNavigate();
  const { heading } = useParams();
  const [newsMediaData, setNewsMediaData] = useState([]);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchDepartmentDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/newsMedia/getAll`);
      setNewsMediaData(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  useEffect(() => {
    fetchDepartmentDataApi();
  }, []);

  const individualNews = newsMediaData.find(
    (news) => news.Headline === heading
  );
  const removeCurrentNews = newsMediaData.filter((x) => x.Headline !== heading);
  const suggestionNews = removeCurrentNews
    .sort(() => Math.random() - Math.random())
    .slice(0, 5);
  const individualNewsImages = imageArray.find(
    (news) => news?.heading === heading
  );

  const onClickNews = (heading) => {
    navigate(`/newsandmedia/${heading}`);
  };

  if (!individualNews) {
    // Handle the case when 'name' doesn't match any data
    return (
      <Grid style={{ width: "90%", marginTop: "70px" }}>News not found</Grid>
    );
  }

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{ width: "90%", marginTop: "170px" }}
      className={styles.container}>
      <Grid container spacing={6}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={10}
          display="flex"
          flexDirection="column"
          //   alignItems="center"
          //   justifyContent="center"
        >
          <Typography
            color="#747474"
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            // marginTop="10px"
          >
            {individualNews?.Date}
          </Typography>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="35px"
            fontStyle="normal"
            fontWeight="400"
            // marginTop="10px"
          >
            {individualNews.FirstPara}
          </Typography>
          {/* <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            marginTop="10px"
          >
            {individualNews.FirstPara}
          </Typography> */}
          <Grid>
            <img
              src={`${newsPath}/NewsCover/${individualNews.Headline}.jpg`}
              alt=""
              className={styles.mainImage}
            />
          </Grid>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            marginTop="10px">
            {/* <p>A Glorious Tribute to the Guardians of Care</p>
            <p>With utmost joy and admiration, we commemorated the Day of Angels, International Nursing Day, in an unforgettable ceremony. Our esteemed Managing Director, the distinguished Dr. Sheeja G Manoj, graced the occasion with her presence, infusing the event with brilliance. Joining us as the esteemed Chief Guest was the remarkable Mrs. Gayatri Subhash, the illustrious Managing Director of BHIMA Jewellers. Special Thanks to <a href="https://www.facebook.com/Tanishq/?__cft__[0]=AZVSxgRGuWbr7KXnmXnOWUzWlsSyIpL9jWioyf_Cq7KfX02T_910CRzAxdyx72-aAVAYJduDWaMoMFkupnsEZgwXTr6wTky86UtPRtNMEvqsuW96xeDLFPLKfAr869mAr-wS7GcIuUPFPtQZZ-VIEKutzqXSBbQccpf-uBQJnF5kmf1l10uJzNDAPMDDkQWerYk&amp;__tn__=kK-R">Tanishq</a> for the gifts and for sending wishes to our angels.</p>
            <p>We extend our heartfelt gratitude to Mrs. Gayatri Subhash and the dedicated staff of <a href="https://www.facebook.com/bhimatvm/?__cft__[0]=AZVSxgRGuWbr7KXnmXnOWUzWlsSyIpL9jWioyf_Cq7KfX02T_910CRzAxdyx72-aAVAYJduDWaMoMFkupnsEZgwXTr6wTky86UtPRtNMEvqsuW96xeDLFPLKfAr869mAr-wS7GcIuUPFPtQZZ-VIEKutzqXSBbQccpf-uBQJnF5kmf1l10uJzNDAPMDDkQWerYk&amp;__tn__=kK-R">Bhima Jewellery</a>, who magnanimously bestowed gifts upon our exceptional nurses, symbolizing their deep appreciation for their invaluable contributions. This generous gesture resonated with the profound gratitude we hold for these angelic caregivers.</p>
            <p>A noteworthy mention is owed to the dynamic presence of <a href="https://www.facebook.com/REDFMMALAYALAM/?__cft__[0]=AZVSxgRGuWbr7KXnmXnOWUzWlsSyIpL9jWioyf_Cq7KfX02T_910CRzAxdyx72-aAVAYJduDWaMoMFkupnsEZgwXTr6wTky86UtPRtNMEvqsuW96xeDLFPLKfAr869mAr-wS7GcIuUPFPtQZZ-VIEKutzqXSBbQccpf-uBQJnF5kmf1l10uJzNDAPMDDkQWerYk&amp;__tn__=kK-R">Red FM Malayalam</a> RJ Chinchu and her team, who added vibrant hues of enthusiasm and energy to the ambience, amplifying the significance of this momentous occasion.</p>
            <p>On this day, we recognized the extraordinary devotion and unwavering spirit of our nurses, who embody the very essence of compassion and selflessness. Their commitment to healing and nurturing reflects the epitome of noble service.</p>
            <p>May the radiance of this event continue to shine as a testament to the profound impact our nurses have on the lives they touch, and may their selfless dedication forever inspire others to embrace the noble calling of healthcare.</p>
            <p>Happy International Nursing Day!</p> */}
            {individualNews.SecondPara}
          </Typography>
          <Grid container spacing={2} marginTop="20px">
            {individualNewsImages?.subImages.map((images, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center">
                {" "}
                <img
                  src={images?.image}
                  alt=""
                  className={styles.mainImage}
                  width="300px"
                  height="400px"
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/* Suggestion of news media starts */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={2}
          display="flex"
          flexDirection="column"
          //   alignItems="center"
          //   justifyContent="center"
          marginTop="50px">
          <Grid container spacing={3}>
            {suggestionNews.map((news, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display="flex"
                flexDirection="column"
                //   alignItems="center"
                justifyContent="center">
                <Grid
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  flexDirection="column"
                  //   alignItems="center"
                  justifyContent="center">
                  <img src={`/News/NewsCover/${news.Headline}.jpg`} alt="" />
                  <Typography
                    color="#747474"
                    fontFamily="Inter"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="400"
                    // marginTop="10px"
                  >
                    {news.Date}
                  </Typography>
                  <Typography
                    color="var(--dark, #211E1E)"
                    fontFamily="Inter"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="500"
                    // marginTop="10px"
                    onClick={() => {
                      onClickNews(news.Headline);
                    }}>
                    {news.Headline}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/* suggestion of news media end here */}
      </Grid>
    </Container>
  );
}

export default IndividualNews;
