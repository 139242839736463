import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import styles from "./BookAppointment.module.css";

import MapImage from "../../assets/images/contactusmapimage.png";

import axios from "axios";
import { logDOM } from "@testing-library/dom";

function IndividualBookAppointment() {
  const { id } = useParams(); // Extract the 'id' from the URL

  const initialState = {
    fullName: "",
    number: "",
    date: "",
    department: "",
    doctor: "",
    emailId: "",
    message: "",
  };

  const [DoctorData, setDoctorData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [defaultDoctor, setDefaultDoctor] = useState(""); // Step 1

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  //fetch data
  const fetchDepartmentDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/departments/getAll`);

      setDepartmentData(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  const fetchDoctorDataApi = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/doctors/getAll`);
      setDoctorData(response?.data?.data);
      console.log(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      throw Error("Error while fetching data", error);
    }
  };

  useEffect(() => {
    fetchDepartmentDataApi();
    fetchDoctorDataApi();
  }, []);

  console.log(id);

  const filtereDoctor = DoctorData?.find((doct) => {
    return doct.GG_ID === id;
  });

  useEffect(() => {
    // Filter the doctor data based on the id
    const filteredDoctor = DoctorData?.find((doct) => doct.GG_ID === id);

    // Update the initial state with the doctor's name and department if found
    if (filteredDoctor) {
      const doctorName = filteredDoctor.Name;
      const departmentId = filteredDoctor.Department_ID;

      const newdepartment = departmentData.find(
        (dept) => dept.SL_NO === departmentId
      );
      const departmentname = newdepartment?.Department;

      setFormData((prevData) => ({
        ...prevData,
        department: departmentId,
        doctor: doctorName,
        // Assuming you want to set Department_ID here
      }));
    }
  }, [DoctorData, departmentData, id]);
  console.log(formData);
  //appointment_booking start

  const FORMSPARK_ACTION_URL = `${apiBaseUrl}/api/masters/bookAppointment`;

  //on value change set data
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //validation of data
  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName) {
      newErrors.fullName = "Full Name is required";
    }
    if (!formData.number) {
      newErrors.number = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.number)) {
      newErrors.number = "Phone Number must be exactly 10 digits";
    }
    if (!formData.date) {
      newErrors.date = "Date is required";
    }
    if (!formData.department) {
      newErrors.department = "Department is required";
    }
    if (!formData.emailId) {
      newErrors.emailId = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailId)) {
      newErrors.emailId = "Invalid email format";
    }
    if (!formData.doctor) {
      newErrors.doctor = "Doctor is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Submit API call
  async function submitBooking(formData) {
    return await fetch(FORMSPARK_ACTION_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });
  }

  // Handling success
  function handleSuccess(data) {
    if (data?.status === 403) {
      toast.error(data?.message);
      setFormData(initialState);
      setErrors({});
    } else {
      toast.success(data?.message);
      setFormData(initialState);
      setErrors({});
    }
  }

  // Handling error
  function handleError() {
    toast.error("An error occurred. Please try again later.");
  }

  const handleBookAppointment = async () => {
    if (validateForm()) {
      try {
        formData.department = departmentData.find(
          (dept) => dept.SL_NO === formData.department
        ).Department;

        const response = await submitBooking(formData);
        if (response.ok) {
          const data = await response.json();
          handleSuccess(data);
        } else {
          handleError();
        }
        // toast.success("Appointment booked successfully!");
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Appointment not booked!");
      }
    }
  };

  //appointment_booking end

  const selectedDoctor = DoctorData.find(
    (doctor) => doctor.Name === formData.doctor
  );

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        marginTop: "170px",
      }}>
      <Grid
        container
        spacing={4}
        sx={{ marginTop: "42px", marginBottom: "300px" }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                // marginTop="10px"
              >
                We are here for you
              </Typography>
              <Typography
                color="var(--dark, #211E1E)"
                fontFamily="Inter"
                fontSize="35px"
                fontStyle="normal"
                fontWeight="700"
                letterSpacing="0.7px">
                Book Appointment
              </Typography>
            </Grid>
            <Grid item xs={6} marginTop="10px">
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                  height: "82px",
                  "& .MuiSelect-root": { backgroundColor: "transparent" },
                }}>
                <InputLabel> Department</InputLabel>
                <Select
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                  error={!!errors.department}
                  label="Department">
                  {departmentData.map((dept) => (
                    <MenuItem value={dept.SL_NO}>{dept.Department}</MenuItem>
                  ))}
                </Select>
                {errors.department && (
                  <Typography color="error">{errors.department}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6} marginTop="10px">
              {" "}
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                  height: "82px",
                  "& .MuiSelect-root": { backgroundColor: "transparent" },
                }}>
                <InputLabel>Doctor</InputLabel>
                <Select
                  name="doctor"
                  value={formData.doctor}
                  onChange={handleChange}
                  error={!!errors.doctor}
                  label="Doctor">
                  {DoctorData.filter((doctor) => {
                    // console.log(doctor.Department_ID);
                    return doctor.Department_ID === formData.department;
                  }).map((doctor) => {
                    // console.log(doctor);
                    return (
                      <MenuItem key={doctor.id} value={doctor.Name}>
                        {doctor.Name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.doctor && (
                  <Typography color="error">{errors.doctor}</Typography>
                )}
              </FormControl>
            </Grid>
            {selectedDoctor &&
              selectedDoctor.Timing &&
              selectedDoctor.Timing.length > 0 && (
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ marginTop: 1 }}>
                    Timing: {selectedDoctor.Timing}
                  </Typography>
                </Grid>
              )}
            <Grid item xs={6} marginTop="10px">
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                error={!!errors.fullName}
                helperText={errors.fullName}
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>
            <Grid item xs={6} marginTop="10px">
              <TextField
                label="Email Id"
                variant="outlined"
                fullWidth
                name="emailId"
                error={!!errors.emailId}
                helperText={errors.emailId}
                value={formData.emailId}
                onChange={handleChange}
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>
            <Grid item xs={6} marginTop="10px">
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                name="number"
                value={formData.number}
                onChange={handleChange}
                error={!!errors.number}
                helperText={errors.number}
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
              />
            </Grid>

            <Grid item xs={6} marginTop="10px">
              <TextField
                variant="outlined"
                label="Date"
                fullWidth
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                error={!!errors.date}
                helperText={errors.date}
                sx={{
                  // width: "350px",
                  height: "77px",
                  "& input": { backgroundColor: "transparent" },
                }}
                InputLabelProps={{
                  shrink: true, // Prevent the label from collapsing
                }}
                inputProps={{
                  min: currentDate, // Set min attribute to disable dates before the current date
                }}
              />
            </Grid>

            <Grid item xs={12} marginTop="10px">
              {" "}
              <TextField
                label="Message"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              marginTop="10px"
              display="flex"
              justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={handleBookAppointment}
                sx={{
                  textTransform: "none",
                  borderRadius: "6px",
                }}
                className={styles.ViewAllButton}>
                Book Now
              </Button>
              <Toaster position="top-right" reverseOrder={false} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "-200px",
                zIndex: "1000",
              }}>
              {" "}
              <Grid
                container
                width="550px"
                height="100%"
                bgcolor="#FFF"
                borderRadius="4px"
                boxShadow="0px 15px 23px 0px rgba(0, 0, 0, 0.08);"
                padding="20px">
                <Grid item xs={12}>
                  <Typography
                    color="var(--dark, #211E1E)"
                    fontFamily="Inter"
                    fontSize="22px"
                    fontStyle="normal"
                    fontWeight="600">
                    Contact Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Typography
                        color="var(--dark, #211E1E)"
                        fontFamily="Inter"
                        fontSize="18px"
                        fontStyle="normal"
                        fontWeight="600"
                        marginBottom="10px">
                        Emergency No:
                      </Typography>
                      <Typography
                        color="var(--dark, #211E1E)"
                        fontFamily="Inter"
                        fontSize="14px"
                        fontStyle="normal"
                        fontWeight="400">
                        0471 2779101
                      </Typography>
                      <Typography
                        color="var(--dark, #211E1E)"
                        fontFamily="Inter"
                        fontSize="14px"
                        fontStyle="normal"
                        fontWeight="400">
                        0471 2779100
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Typography
                        color="var(--dark, #211E1E)"
                        fontFamily="Inter"
                        fontSize="18px"
                        fontStyle="normal"
                        fontWeight="600"
                        marginBottom="10px">
                        Email Id:
                      </Typography>
                      <Typography
                        color="var(--dark, #211E1E)"
                        fontFamily="Inter"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="400">
                        gghospitalphkpl@gmail.com
                      </Typography>
                      <Typography
                        color="var(--dark, #211E1E)"
                        fontFamily="Inter"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="400">
                        admin@gokulamgghospital.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default IndividualBookAppointment;
